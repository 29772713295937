<template>
  <div>
    <video-player
      v-if="source && currentChapter"
      :source="source"
      :play-start-time="playStartTime"
      :is-playing.sync="isPlaying"
      :loop="loop"
      :subtitles="subtitles"
      :chapter="currentChapter"
      @timeUpdate="timeUpdate"
      @ended="nextVideo"
      @seeked="playStartTime = $event"
    />

    <video-player-controls
      class="w-100"
      v-if="!$store.getters.fullScreenAsDefault"
      :videos="playableVideos"
      :loops="loops"
      :current-chapter="currentChapter"
      :is-playing.sync="isPlaying"
      :show-advanced.sync="showAdvanced"
      @toggleChapters="showChapterModal = !showChapterModal"
      @toggleLoops="showLoopsModal = !showLoopsModal"
      @update:currentChapter="updateChapter"
    />
    <div
      class="chapter-name text-center my-2 text-caption"
      v-if="!$store.getters.fullScreenAsDefault"
    >
      <span style="font-weight: bold" v-if="currentChapter">{{
        currentChapter.displayName
      }}</span>
    </div>
    <b-sidebar
      :visible="showChapterModal"
      id="sidebar-chapters"
      bg-variant="dark"
      text-variant="light"
      aria-labelledby="sidebar-no-header-title"
      no-header
      shadow
    >
      <div class="px-3 py-2">
        <video-chapters
          :videos="videos"
          :current-chapter="currentChapter"
          @update:currentChapter="onSelectChapter"
          @close="showChapterModal = false"
        />
      </div>
    </b-sidebar>

    <b-sidebar
      :visible="showLoopsModal"
      id="sidebar-loops"
      :title="this.loops.length > 0 ? 'Loops' : 'Chapters'"
      bg-variant="dark"
      text-variant="light"
      shadow
    >
      <div class="px-3 py-2">
        <video-chapters
          :loops="loops"
          :currentLoop="currentLoop"
          @update:loop="updateLoop"
          @close="showLoopsModal = false"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "VideoPlayerWithChapters",
  components: {
    VideoPlayer: () => import("./VideoPlayer"),
    VideoChapters: () => import("./VideoChapters"),
    VideoPlayerControls: () => import("./VideoPlayerControls"),
  },
  mixins: [],
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    loops: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showChapterModal: false,
      showLoopsModal: false,
      source: "",
      currentChapter: {},
      currentLoop: {},
      playStartTime: 0,
      isPlaying: false,
      showAdvanced: false,
      currentVideoTime: 0,
      subtitles: [],
      loop: {
        in: null,
        out: null,
        active: false,
      },
    }
  },
  computed: {
    ...mapState(["contentMetaData", "contentID"]),
    xJSON() {
      return this.contentMetaData.xJSON
    },
    fileManifestMap() {
      return this.xJSON.fileManifest.reduce((acc, item) => {
        acc[item.fileName] = item
        return acc
      }, {})
    },
    playableVideos() {
      return this.videos.filter((v) => v.type !== "expandableChapterHeader")
    },
  },
  methods: {
    getChapterForTime(time) {
      const videos = this.playableVideos
        .filter(
          (video) =>
            video.fileName === this.currentChapter.fileName &&
            (video.chapterOffset || 0) <= time,
        )
        .sort((a, b) => (b.chapterOffset || 0) - (a.chapterOffset || 0))
      if (videos.length) return videos[0]
      return this.currentChapter
    },
    async timeUpdate(currentTime) {
      this.currentVideoTime = currentTime
      const nextChapter = this.getChapterForTime(currentTime)
      if (this.currentChapter !== nextChapter) {
        this.playChapter(nextChapter)
      }
    },
    onSelectChapter(chapter) {
      console.log("onSelectChapter: ", chapter)
      this.updateChapter(chapter)
      this.showChapterModal = false
    },
    updateChapter(chapter) {
      this.playChapter(chapter)
      this.playStartTime = chapter.chapterOffset || 0
    },
    updateLoop(loopData) {
      this.currentLoop = loopData
      this.showLoopsModal = false
      this.loop.in = loopData.in
      this.loop.out = loopData.out
      this.playStartTime = loopData.in
      this.loop.active = true
      this.showAdvanced = true
    },
    async playChapter(chapter) {
      console.log("playChapter: ", this.currentChapter, chapter)
      if (
        !this.currentChapter?.fileName ||
        this.currentChapter?.fileName !== chapter?.fileName
      ) {
        const res = await this.signURL(chapter?.fileName)
        console.log("signURL res: ", res)

        if (res?.messageCode === "noAccess") {
          this.$bvModal.hide(this.contentID)
          this.$bvToast.toast(`Sorry You Have No Access`, {
            autoHideDelay: 3500,
            appendToast: true,
            variant: "danger",
          })
          return
        }

        if (res.signedURL) {
          this.source = res.signedURL
          if (res.subtitles) {
            this.subtitles =
              res.subtitles.map((subtitle) => {
                return Object.assign(
                  subtitle,
                  this.fileManifestMap[subtitle.fileName],
                )
              }) || []
          }
        }
      }
      this.currentChapter = chapter
    },
    async signURL(fileName) {
      const res = await this.$api("content/mediaRequest", {
        type: "video",
        contentID: this.contentMetaData.id,
        fileName,
      })
      return res.data
    },
    nextVideo() {
      const nextIndex = this.playableVideos.indexOf(this.currentChapter) + 1
      const nextVideo = this.playableVideos[nextIndex]
      if (!nextVideo) {
        return this.$bvModal.hide(this.contentID)
      }
      this.updateChapter(nextVideo)
    },
  },
  watch : {
    isPlaying (newVal) {
      this.showChapterModal = !newVal
    },
  },
  created() {
    this.playChapter(this.playableVideos[0])
    this.playStartTime = 0
  },
}
</script>
